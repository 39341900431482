/* General Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #8A817C;
}

body::-webkit-scrollbar-thumb {
  background-color: #F1C40F;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(241, 196, 15, 0.5);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 75px);
}

/* Section Styles */
section {
  padding: 2em;
  background-color: #F6F7F8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}