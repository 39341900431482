nav {
  background-color: #E63946;
  position: sticky;
  top: 0;
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.nav-list a {
  text-decoration: none;
  color: #F6F7F8;
  padding: 1em;
  transition: color 0.3s ease;
  font-weight: bold;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #F6F7F8;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .nav-list {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #E63946;
    z-index: 99;
    padding-top: 20px;
  }

  .nav-list.active {
    display: flex;
  }

  .menu-icon {
    display: flex;
  }

  section:not(:first-child) {
    padding-top: 80px;
  }

  .nav-list li {
    margin-bottom: 10px;
  }
 
  .nav-list a {
    text-decoration: none;
    color: #F6F7F8;
    transition: color 0.3s ease;
    padding: 10px;
    display: block;
    font-weight: bold;
  }
}

.nav-list a:hover {
  color: #2d3436;
}