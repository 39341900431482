.energetic-services-section {
  background-color: #F6F7F8;
  padding: 2em;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
  color: #eb7a01;
  font-size: 2em;
  margin-bottom: 1em;
  }
  
  .energetic-text {
  color: #2E3131; 
  font-size: 1.2em;
  line-height: 1.6;
  }

  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .expertise-item {
      width: calc(100% - 20px);
      margin-bottom: 5px;
    }
  }
  
  /* Hover Effect */
  .expertise-item:hover {
    transform: scale(1.05);
  }

  