/* General Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #2E3131;
}

body::-webkit-scrollbar-thumb {
  background-color: #d63031;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #e448486d;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 75px);
}

