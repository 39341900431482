/* Header Styles */
header {
    background-color:#2E3131;
    color: #F6F7F8;
    text-align: center;
    padding: 1em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
.header-content {
    display: grid;
    grid-template-columns: 1fr 40% 1fr;
    align-items: center;
    padding: 20px;
    width: 50%;
}
  
.header-text {
    grid-column: 1;
    text-align: center;
    width: 200px;
}
  
.profile-image {
    grid-column: 3;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    animation: spin 2s linear 1;
}
  
/* Define the spinning animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
/* Hover effect for a slight growth */
.profile-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
  
/* Responsive layout */
@media (max-width: 768px) {
    .header-content {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .header-text {
        grid-column: 1;
        width: 100%;
    }
    .profile-image {
        grid-column: 1;
    }
}


