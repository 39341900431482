/* Scroll to Top Button Styles */
#scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #a35ccf;
  border: none;
  color: #F6F7F8;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid #2d3436;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

#scrollToTopBtn:hover {
  background-color: #636e72;
  color: #F6F7F8;
}

#scrollToTopBtn {
  z-index: 9999;
}
