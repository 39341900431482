.testimonials-section {
  padding: 2em;
  background-color: #F6F7F8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.testimonial-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
